<template>
  <div class="new-user">
    <div class="centered-container">
      <md-content class="md-elevation-3">
        <p><span class="md-title">Condições de utilização</span></p>
        <p><span class="md-caption">Para aceder ao Portal de Projetos por favor aceite os Termos e Condições descritos abaixo.</span></p>
        <div class="private">
          <perfect-scrollbar v-if="src">
          <pdf
            v-for="i in numPages"
            :key="i"
            :src="src"
            :page="i"
            class="page-pdf"
          ></pdf>
          </perfect-scrollbar>
        </div>

        <div class="actions md-layout md-alignment-left">
          <md-button class="md-raised md-primary" @click="accept">Aceitar</md-button>
        </div>

        <div class="loading-overlay" v-if="loading">
          <md-progress-spinner md-mode="indeterminate" :md-stroke="2"></md-progress-spinner>
        </div>
      </md-content>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import * as Constants from '@/store/constants'
import Auth from '@/api/services/Auth.js'

export default {
  name: 'Disclaimer',
  data () {
    return {
      src: '',
      numPages: undefined
    }
  },
  components: {
    pdf,
    PerfectScrollbar
  },
  methods: {
    accept () {
      Auth.setDisclaimer().then(response => {
        this.$store.commit('setDisclaimer', new Date())
        this.$router.push('/')
      }).catch(() => {
        this.$router.push('/')
      })
    }
  },
  created () {
    Auth.getDisclaimerUrl().then(response => {
      this.src = pdf.createLoadingTask(Constants.BASEURL + '/media/' + response.data.url)
      this.src.then(pdf => {
        this.numPages = pdf.numPages
      })
    }).catch(() => {
      this.$router.push('/')
    })
  }
}
</script>

<style lang="scss" scoped>
.ps {
  height: 400px;
  background-color: rgba(0, 0, 0, 0.3)
}
.page-pdf {
  margin-bottom: 10px;
}
.private {
  max-height: 450px;
  border-top-color: rgba(0,0,0, .5);
  border-top-style: solid;
  border-top-width: 1px;
  // border-right-color: transparent;
  border-right-style: solid;
  border-right-width: 1px;
  // border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  // border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.label-confirm:after {
  background-color: rgba(0, 0, 0, 0) !important;
}
.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .title {
    text-align: center;
    margin-bottom: 30px;
    img {
      margin-bottom: 16px;
      max-width: 80px;
    }
  }
  .actions {
    .md-button {
      margin: 0;
    }
  }
  .form {
    margin-bottom: 60px;
  }
  .md-content {
    z-index: 1;
    padding: 40px;
    width: 100%;
    max-width: 1024px;
    position: relative;
  }
  .loading-overlay {
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"></style>
